import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillSignStopFill } from "react-icons/bs";
import InputField from "../../components/common/inputField";
import axios from "axios";
import TopPerformersConsistency from "../../components/topPerformerConsistency";
import TopPerformersFitnessScore from "../../components/topPerformersFitnessScore";
import ClientAnalytics from "../clientAnalytics";
import TLSgraph from "../../components/TLSgraph";
import WeeklyActiveUsersGraph from "../../components/weeklyActiveUsersGraph";
import MemberFeedback from "../feedback";
import CoachWiseClients from "./CoachWiseClients";
import SearchBar from "./SearchBar";

const CoachTools2 = () => {
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [clientCode, setClientCode] = useState("");
  const [clientApiResp, setClientApiResp] = useState([]);
  const [coachWiseClients, setCoachWiseClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeClientCount, setActiveClientCount] = useState(0);

  const navigate = useNavigate();

  const fetchClientList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_INSIGHT_SERVICE_BASE_URL}/client/active`
      );
      const clientList = res.data;

      const cnOptions = clientList.map((client) => ({
        value: client.code,
        label: client.name,
      }));

      setClientApiResp(clientList);
      setClientNameOptions(cnOptions);
      setActiveClientCount(clientList.length);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClientList();
  }, []);

  const handleChange = (name, value) => {
    if (name === "clientName") {
      setClientCode(value);
    }
  };

  const navigateToClientAnalytics = () => {
    navigate(`/analytics/client`);
  };

  const navigateToProfile = () => {
    navigate(`/client/${clientCode}`);
  };

  const fetchCoachWiseClients = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/consistency-tls`
      );
      setCoachWiseClients(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClientList();
    fetchCoachWiseClients();
  }, []);

  return (
    <div className="flex flex-col items-center text-black bg-white p-1 sm:p-14 mb-8">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between w-full md:px-10">
        <h2 className="uppercase text-[40px] md:text-[50px] text-black font-bebas tracking-wide mb-6 md:mb-0">
          Coach Tools
        </h2>
        <div className="flex flex-col md:flex-row items-center gap-6">
          <div className="text-black border-blue-gray-800 h-20 border-2 p-2 rounded-md flex flex-col text-center">
            <span className="text-2xl font-bold text-[#8991F0]">
              {activeClientCount}
            </span>
            <span className="text-sm">(Total Members)</span>
          </div>
          <SearchBar clientNameOptions={clientNameOptions} />
        </div>
      </div>

      {/* Client Data Section */}
      <div className="w-full flex items-center justify-center mt-6">
        <CoachWiseClients clientData={coachWiseClients} />
      </div>

      {/* Graph Section */}
      <div className="w-full flex items-center justify-center mt-6">
        <div className="flex flex-col border border-gray-300 rounded p-3 mx-2 shadow-md space-y-4 w-full sm:w-11/12">
          <WeeklyActiveUsersGraph />
        </div>
      </div>
    </div>
  );
};

export default CoachTools2;
