export const ALL_AVAILABLE_SECTIONS = [
  "MOB",
  "ACT",
  "GYM",
  "STR",
  "MR",
  "HYP",
  "HYP2",
  "HYP3",
  "METCON",
  "CORE",
  "ISO",
];

export const SECTION_CODE_NAME = {
  MOB: "Mobility",
  ACT: "Activation",
  GYM: "Gymnastics",
  STR: "Strength",
  MR: "Mid Range",
  HYP: "Hypertrophy",
  HYP2: "Hypertrophy II",
  HYP3: "Hypertrophy III",
  METCON: "Metabolic Conditioning",
  CORE: "Core",
  ISO: "Isolation",
};

export const ALL_AVAILABLE_EQUIPMENTS = [
  "Banded",
  "Dumbbell",
  "Barbell",
  "Kettlebell",
  "Machine",
  "Cable",
];

export const ALL_AVAILABLE_MVTM_BLACKLIST_TAGS = ["jumping", "hinging"];

export const ALL_AVAILABLE_MVTM_LEVEL_TAGS = [
  "beginner",
  "intermediate",
  "advanced",
];

export const ALL_AVAILABLE_COACHES = [
  "Rishi",
  "Natalie",
  "Pranav",
  "Dilshad",
  "Sanika",
];

export const DEFAULT_SECTION_FORMATS = ALL_AVAILABLE_SECTIONS.map(
  (section) => ({
    sectionCode: section,
    rounds: "2 Rounds",
  })
);

export const TOGGLE_ARROW = {
  on: "https://assets-global.website-files.com/639c596c47e46bddbb2c8bed/656c5173d4a1c4e89d1f9ca5_toogleOn.png",
  off: "https://assets-global.website-files.com/639c596c47e46bddbb2c8bed/656c5173440c9848c5431980_toogleOff.png",
};

const METCONTYPES = {
  ROUNDS: "Rounds for Time",
  EMOM: "EMOM",
  AMRAP: "AMRAP",
  // TFN: '21-15-9',
  // FFTTT: '50-40-30-20-10'
};

export const METCONFORMAT = [
  {
    name: METCONTYPES.ROUNDS,
    hint: "",
  },
  {
    name: METCONTYPES.EMOM,
    hint: " (45 sec work each round)",
  },
  {
    name: METCONTYPES.AMRAP,
    hint: " (As many rounds as possible)",
    // reps: lib * // 90(8) 120(6) 150(4) | reps_per_round * round
    // time: 12
  },
  // {
  //   name: METCONTYPES.FFTTT,
  //   reps: 150,
  //   time: 20 // auto calc from time defined
  // }
  // {
  //   name: '1MOn1MOff'
  // }
];

export const ROUND_OPTIONS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];

export const DAILY_ZONES = ["Morning Zone", "Evening Zone"];

export const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const MONTH_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAILY_LIFESTYLE_TASKS = [
  { taskId: "BRTH", taskName: "Box Breathing" },
  { taskId: "ACVH2O", taskName: "ACV in Water" },
  { taskId: "STR", taskName: "Dynamic Stretches" },
  { taskId: "DETOX", taskName: "Morning Detox with Meditation" },
  { taskId: "MORNEX", taskName: "Morning Exercise" },
  { taskId: "POSTEXH", taskName: "Post-Exercise Hydration" },
  { taskId: "MNBRNCH", taskName: "Mid-Morning Brunch" },
  { taskId: "AFTWLK", taskName: "Post-Brunch Walk" },
  { taskId: "LNCH", taskName: "Lunch" },
  { taskId: "POSTLUNCHWLK", taskName: "Post-Lunch Walk" },
  { taskId: "AFTSNCK", taskName: "Afternoon Snack" },
  { taskId: "EVNWALK", taskName: "Evening Walk" },
  { taskId: "EVNMEDIT", taskName: "Evening Meditation" },
  { taskId: "DINACV", taskName: "ACV Before Dinner" },
  { taskId: "DIN", taskName: "Dinner" },
  { taskId: "POSTDINWLK", taskName: "Post-Dinner Walk" },
  { taskId: "NIGHTYOGA", taskName: "Yoga Nidra" },
];

export const WORKOUT_SPLIT_DATA = [
  { splitId: 3, splitData: "3 workout split (Push, Pull, Squat)" },
  { splitId: 4, splitData: "4 workout Split (H Push, Pull, Squat, V Push)" },
  {
    splitId: 5,
    splitData: "5 workout Split (H Push, Pull, Squat, V Push, Posterior Chain)",
  },
];
