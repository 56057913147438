import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdEmail, MdArrowBack } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import Loader from "../../components/Loader";
import SettingsPage from "./SettingsPage";
const ClientProfile2 = () => {
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weeklyCheckInData, setWeeklyCheckInData] = useState([]);
  // const [completionHistory, setCompletionHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [fitnessGraphData, setFitnessGraphData] = useState(null);
  const [workoutGraphData, setWorkoutGraphData] = useState(null);
  const [radarChartData, setRadarChartData] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [activeSection, setActiveSection] = useState("profile");

  const { clientCode } = useParams();
  const navigate = useNavigate();

  const fetchCalendarData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/lifestyle?user=${clientCode}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching calendar data:", error);
      return null;
    }
  };

  const fetchRadarChartData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/monthly-wrapped?user=${clientCode}&month=${new Date().getMonth()}&year=${new Date().getFullYear()}`
      );
      const { currMonthSkillPoint, prevMonthSkillPoint, bestSkill } =
        response.data.data.wrapped;
      setRadarChartData({
        currMonthSkillPoint,
        prevMonthSkillPoint,
        bestSkill,
      });
    } catch (error) {
      console.error("Error fetching radar chart data:", error);
    }
  };

  const fetchActivityData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/insights/weekly-activity-list?memberCode=${clientCode}`
      );

      if (!response.data.success) {
        throw new Error("Network response was not ok");
      }

      const data = response.data.data;

      return data;
    } catch (error) {
      console.error("Error fetching activity data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientResponse, checkInResponse, calendarData, activityData] =
          await Promise.all([
            axios.get(
              `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/client/profile/coach-tools?memberCode=${clientCode}`
            ),
            axios.get(
              `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/weekly-checkin?memberCode=${clientCode}`
            ),
            fetchCalendarData(),
            fetchActivityData(),
          ]);
        setClientData(clientResponse.data.data);
        setWeeklyCheckInData(checkInResponse.data.data);
        setCalendarData(calendarData);
        setActivityList(activityData);

        const reportId = clientResponse.data.data.renewalReportId;

        if (reportId) {
          // Fetch journey reflection data
          const journeyReflectionResponse = await axios.get(
            `${process.env.REACT_APP_MAIN_SERVICE_BASE_URL}/api/v1/renewal-report?reportId=${reportId}`
          );
          setFitnessGraphData(journeyReflectionResponse.data);
          setWorkoutGraphData(journeyReflectionResponse.data);
        }
        await fetchRadarChartData();
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [clientCode]);

  if (loading) return <Loader />;
  if (error) return <p>Error loading data</p>;

  const {
    name,
    email,
    totalWorkoutsDone,
    lastEightWeeksWorkout,
    consistencyTrend,
    avgLastFourWeekConsistency,
    // lastWorkoutDate,
    // profilePicture,
    // workoutRank,
    // isPaymentDue,
    // membershipEndDate,
    // skills,
    coach,
    program,
    lifetimeInMonths,
    fitnessScore,
    fitnessScoreImprovement,
    rateOfFSI,
    questionnaireResponse,
    perfectWeeks,
    totalWeeks,
    stepCount,
    joiningDate,
  } = clientData;

  const consistencyColor =
    avgLastFourWeekConsistency > 2.5
      ? "green"
      : avgLastFourWeekConsistency > 1.2
        ? "yellow"
        : "red";

  // const latestCheckIn = weeklyCheckInData[0] || {};
  // console.log(fitnessGraphData);

  return (
    <div className="relative w-full min-h-screen overflow-x-hidden bg-white text-black flex flex-col items-center px-3">
      <div className="absolute top-4 left-4 z-10">
        <MdArrowBack
          className="text-black cursor-pointer"
          size={30} // Reduce the icon size for smaller screens
          onClick={() => navigate("/coach-tools-2")}
        />
      </div>
      <div className="flex flex-col justify-center items-center text-black w-full pt-16">
        <div className="border rounded-md w-full sm:w-[90%] p-2">
          <div className="w-full flex flex-col md:flex-row justify-between items-center p-2">
            <div className="flex flex-col gap-2">
              <h2 className="text-xl sm:text-2xl">{name}</h2>
              <h4 className="text-sm sm:text-base">
                Member Code: <span>{clientCode}</span>
              </h4>
            </div>
            <div className="flex gap-4 mt-4 md:mt-0">
              <div className="flex flex-col gap-2">
                <p className="text-sm sm:text-base">{email}</p>
                <p className="text-sm sm:text-base">
                  {" "}
                  Joining Date:{" "}
                  {new Date(joiningDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  })}
                </p>
              </div>
              <div className="flex items-center justify-center">
                <FaUserCircle size={50} color={consistencyColor} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SettingsPage clientData={clientData} />

      {/* weekly stats */}
      {/* <div className="rounded-md w-full sm:w-full md:w-11/12 lg:w-4/5 mt-4 backdrop-blur-md">
        <h2 className="text-2xl font-bold m-4">Stats This Week</h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-8 gap-4 mt-4">
          <div className="col-span-1 sm:col-span-1 md:col-span-3 flex flex-col mx-2 px-4 pt-2">
            <h3 className="text-bold text-lg">Activities Completed</h3>
            <div>
              {activityList &&
                activityList.length > 0 &&
                activityList.map((activity, index) => (
                  <div key={index} className="flex flex-col mt-1">
                    <div className="text-lg">{activity.date}</div>
                    <ul>
                      {activity.activities.length > 0 ? (
                        activity.activities.map((act, index) => (
                          <li key={index} className="text-sm">
                            {" "}
                            - {act}
                          </li>
                        ))
                      ) : (
                        <li className="text-sm"> - </li>
                      )}
                    </ul>
                  </div>
                ))}
            </div>
          </div>

          <div className="col-span-1 sm:col-span-1 md:col-span-5 flex flex-col p-2">
            {stepCount && stepCount.length > 0 && (
              <div className="w-full flex flex-col items-center">
                <StepCountGraph stepsArray={stepCount} />
                <h2 className="text-sm font-bold mt-2">Step Count</h2>
              </div>
            )}
            <div className="w-full flex flex-col items-center mt-4">
              <CaloriesTracker memberCode={clientCode} />
            </div>
            <div className="rounded-md sm:w-full md:w-1/2 flex flex-col items-center mt-4">
              <h2 className="text-xl font-bold">Lifestyle Completion</h2>
              {calendarData && (
                <Calendar
                  completionHistory={calendarData.completionHistory}
                  isSummaryPage={false}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      */}
      {/* 
      <div className="rounded-md w-full md:w-11/12 lg:w-4/5 mt-4 backdrop-blur-md">
        <h2 className="text-2xl font-bold m-4">Lifetime Stats</h2>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
          <div className="col-span-5 rounded-md backdrop-blur-md">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div className="rounded-md p-2 mt-1">
                <h2 className="text-lg font-bold">Weekly Metrics</h2>
                {lastEightWeeksWorkout && lastEightWeeksWorkout.length > 0 && (
                  <WorkoutChart workouts={lastEightWeeksWorkout} />
                )}
              </div>
              <div className="rounded-md pt-8">
                {workoutGraphData && (
                  <WorkoutGraph apiData={workoutGraphData} />
                )}
              </div>
            </div>
            <div className="grid grid-rows-1 gap-1 mb-4">
              <div>
                <h2 className="text-lg font-bold pl-3">Monthly Metrics</h2>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="rounded-md p-2">
                  {fitnessGraphData && (
                    <FitnessGraph apiData={fitnessGraphData} />
                  )}
                </div>
                <div className="rounded-md p-2">
                  {radarChartData && (
                    <RadarChart radarChartData={radarChartData} />
                  )}
                </div>
              </div>
            </div>
          </div>
          {clientData.achievements && (
            <div className="col-span-2 rounded-md backdrop-blur-md">
              <h1 className="text-lg font-bold mb-4 ml-2 mt-2">Achievements</h1>
              <div className="flex flex-col">
                {clientData.achievements.strength && (
                  <div className="bg-gray-800 p-2 border-gray-800 rounded-lg shadow-md">
                    <h2 className="text-sm font-semibold mb-1">Strength</h2>
                    <p>{clientData.achievements.strength}</p>
                  </div>
                )}
                {clientData.achievements.hypertrophy && (
                  <div className="bg-gray-800 p-2 rounded-lg shadow-md mb-4">
                    <h2 className="text-sm font-semibold mb-1">Hypertrophy</h2>
                    <p>{clientData.achievements.hypertrophy}</p>
                  </div>
                )}
                {clientData.achievements.midRange && (
                  <div className="bg-gray-800 p-2 rounded-lg shadow-md mb-2">
                    <h2 className="text-sm font-semibold mb-1">Mid Range</h2>
                    <p>{clientData.achievements.midRange}</p>
                  </div>
                )}
                {clientData.achievements.fitnessTests && (
                  <div className="bg-gray-800 p-2 rounded-lg shadow-md mb-2">
                    <h2 className="text-sm font-semibold mb-1">
                      Fitness Tests
                    </h2>
                    <p>{clientData.achievements.fitnessTests}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div> 
      <div className="border rounded-md w-full md:w-11/12 lg:w-4/5 mt-4 p-4 backdrop-blur-md px-2">
        <h2 className="text-2xl font-bold mb-4">MAIN STATS</h2>
        <div className="flex flex-col sm:flex-row justify-between mb-4">
          <div className="text-center sm:flex-1">
            <p className="text-lg font-semibold">Perfect Weeks</p>
            <p className="sm:text-4xl text-3xl font-bold text-[#8080ff] font-anton pt-2">
              {perfectWeeks} / {totalWeeks}
            </p>
          </div>
          <div className="text-center sm:flex-1">
            <p className="text-lg font-semibold">Total Workouts</p>
            <p className="sm:text-4xl text-3xl font-bold text-[#8080ff] font-anton pt-2">
              {totalWorkoutsDone}
            </p>
          </div>
          <div className="sm:flex-1 mt-4 sm:mt-0">
            <StepCountGraph stepsArray={stepCount} />
          </div>
        </div>
        <div className="flex-col flex sm:flex-row justify-between">
          <div className="rounded-md p-2 sm:w-1/2 w-full">
            {calendarData && (
              <Calendar
                completionHistory={calendarData.completionHistory}
                isSummaryPage={false}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            )}
          </div>
          <div className="rounded-md p-2 sm:w-1/2 w-full mt-8 sm:mt-0">
            <WeeklyWorkoutReport
              consistencyTrend={consistencyTrend}
              suggestedWorkoutPerWeek={4}
              lastEightWeeksWorkout={lastEightWeeksWorkout}
            />
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

export default ClientProfile2;
